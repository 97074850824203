.react-slideshow-container {
  background-color: #000000;
}

.slider-textcontainer {
  position: absolute;
  top: -217px;
  margin-left: 60px;
  font-size: 50px;

  h1 {
    color: white;
  }
}

.each-slide-effect > div {
  display: flex;
  height: 885px;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  opacity: 80%;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .slider-textcontainer {
    font-size: 17px;
  }
}