* {
  margin: 0;
  padding: 0;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
}

body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-family: inter,sans-serif;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}