#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.brand-title {
  font-size: 30px;
  color: #333333;
  text-decoration: none;
  font-weight: 700;
  margin-left: 15rem;
  width: 30rem;

  .navbar-logo {
    width: 13px;
    margin-bottom: 10px;
    color: #3b83f6;
    font-weight: 600;
  }
}

.navbar-links {
  margin-right: 15rem;
  margin-left: 23rem;
  width: 40rem;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }

  li {
    text-align: center;
    padding: 1rem 0rem 1rem 0rem;
  }

  a {
    color: #7A7D87;
    text-decoration: none;
    display: block;
  }

  li:hover a {
    color: #46474B;
  }

  li:last-child {
    background-color: #333333;
    border-radius: 2rem;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;

    a {
      color: #FFFFFF;
      font-weight: 700;
    }

    a:hover {
      color: #FFFFFF;
    }
  }

  li:last-child:hover {
    background-color: #3b83f6;
    box-shadow: 0px 15px 20px rgba(59, 131, 246, 0.4);
    transform: translateY(-2px);
  }
}

.navbar-links.active {
  display: flex;
}

.toggle-button {
  position: absolute;
  display: none;
  flex-direction: column;
  top: 1.25rem;
  right: 1rem;
  justify-content: space-between;
  width: 30px;
}

.navbar-hamburger {
  width: 100%;
  height: 22px;
  border-radius: 10px;
  color: black;
}

@media (max-width: 1444px) {
  .toggle-button {
    display: flex;
  }

  .brand-title {
    margin-left: 2rem;
    width: 14rem;
  }

  .navbar-links {
    display: none;
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;

    ul {
      flex-direction: column;
      width: 18rem;
      margin: 0 auto;

      li {
        width: 100%;
        text-align: center;
      }
    }
  }

  #navbar {
    flex-direction: column;
    align-items: flex-start;
  }
}