#aboutme {
  display: flex;
  background-color: white;
  height: auto;

  .aboutme-container {
    flex: 1;
    padding: 5rem;

    span {
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #3b83f6;
    }

    h2 {
      font-size: 3rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      padding-top: 3.5rem;
      max-width: 80%;
      padding-bottom: 3rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: #7A7D87;
    }

    a {
      background-color: #333333;
      border-radius: 2rem;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      color: #FFFFFF;
      font-weight: 700;
      padding: 15px 25px 15px 25px;
      text-decoration: none;
    }

    a:hover {
      color: #FFFFFF;
      background-color: #3b83f6;
      box-shadow: 0px 15px 20px rgba(59, 131, 246, 0.4);
      transform: translateY(-2px);
    }
  }

  .aboutme-image {
    max-width: 500px;
    height: auto;
    padding: 5rem;
    margin: 0 auto;
  }
}

@media (max-width: 1146px) {
  #aboutme {
    flex-direction: column;

    .aboutme-container {
      text-align: center;
      padding: 0rem;
      padding-top: 2rem;

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 0.9rem;
        padding-left: 2rem;
      }
    }

    .aboutme-image {
      max-width: 60%;
      height: auto;
      padding: 4rem;
    }
  }
}
