#Footer {
  width: 100%;
  height: 4rem;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: medium;
  color: #CDCDCD;
}