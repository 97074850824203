.HomePage-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(75%);
  z-index: 0;
}

.Homepage-center {
  width: 100px;
}


#particles {
  width: 100%;
  height: 100%;
  overflow: hidden;
}