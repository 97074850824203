#projects {
  display: flex;
  background-color: #f7f7f7;
  height: auto;

  .projects-container {
    flex: 1;
    padding: 5rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap; /* Allow projects to wrap to the next line */
    justify-content: space-evenly; /* Adjust as needed */

    span {
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #3b83f6;
    }

    h2 {
      font-size: 3rem;
      padding-bottom: 3.5rem;
    }

    .project {
      display: flex; /* Use flexbox */
      flex-direction: column; /* Stack children vertically */
      margin: 20px;
      padding: 20px;
      border: 1px solid #DEDCDC;
      border-radius: 6px;
      background-color: #FFFFFF;
      transition: transform 0.3s;
      width: 22.10rem;
      min-height: 200px; /* Set your desired minimum height */

      .project-title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin: 0;
        text-align: center;
      }

      p {
        font-size: 14px;
        color: #555;
        margin: 15px 0;
      }

      img {
        width: 100%;
        max-height: 200px;
        object-fit: cover;
        border-radius: 6px;
      }

      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
      }
    }
  }
}

@media (max-width: 768px) {
  #projects {
    flex-direction: column;

    .projects-container {
      padding: 0rem;
      padding-top: 2rem;

      h2 {
        font-size: 2rem;
      }

      .project {
        max-width: 77%;
        height: auto;
      }
    }
  }
}

